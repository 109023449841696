<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      support_chats: [],
      support_questions: [],
      selectedRequest: {},
      SupportRequests: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSupportRequests: null,
      SupportRequestsSearchMode: false,
      searchModel: "",
      state: state,
      support_status: [],
    };
  },
  methods: {
    getSupportStatus() {
      this.http.get("support-status").then((res) => {
        this.support_status = res.data;
      });
    },
    changeIt(app) {
      var data = {
        title: "popups.change_status",
        inputs: [
          {
            model: "status_id",
            type: "select",
            options: (() => {
              return this.support_status.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "support_requests.status_id",
          },
        ],
        buttons: [
          {
            text: "popups.change_status",
            closer: true,
            color: "success",
            handler: (obj) => {
              obj.id = app.id;
              this.http.put("support-requests", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    addSupportRequests() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addSupportRequests",
        inputs: [
          { model: "user_id", type: "text", label: "user_id" },
          { model: "support_id", type: "text", label: "support_id" },
          { model: "order_id", type: "text", label: "order_id" },
          { model: "status_id", type: "text", label: "status_id" },
          {
            model: "support_chats",
            type: "select",
            options: (() => {
              return this.support_chats.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.support_chats",
          },

          {
            model: "support_questions",
            type: "select",
            options: (() => {
              return this.support_questions.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.support_questions",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("support-requests", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editSupportRequests(app) {
      var data = {
        title: "popups.support_requests",
        inputs: [
          {
            model: "user_id",
            type: "text",
            label: "user_id",
            value: app.user_id,
          },
          {
            model: "support_id",
            type: "text",
            label: "support_id",
            value: app.support_id,
          },
          {
            model: "order_id",
            type: "text",
            label: "order_id",
            value: app.order_id,
          },
          {
            model: "status_id",
            type: "text",
            label: "status_id",
            value: app.status_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("support-requests", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateSupportRequests(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "user_id", type: "text", label: "user_id" },
          { model: "support_id", type: "text", label: "support_id" },
          { model: "order_id", type: "text", label: "order_id" },
          { model: "status_id", type: "text", label: "status_id" },
          {
            model: "support_chats",
            type: "select",
            options: (() => {
              return this.support_chats.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.support_chats",
          },

          {
            model: "support_questions",
            type: "select",
            options: (() => {
              return this.support_questions.map((data) => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.support_questions",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("support-requests", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.SupportRequestsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("support-requests/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "order_id",
        })
        .then((res) => {
          this.SupportRequests = res.data;
          console.log("######## files", this.SupportRequests);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.SupportRequestsSearchMode = false;
      this.get(this.page);
    },

    getSupportRequests() {
      this.http.get("support-requests").then((res) => {
        this.SupportRequests = res.data;
      });
    },
    deleteSupportRequests(app) {
      var data = {
        title: "popups.delete_title",
        msg: "popups.delete_message",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("support-requests", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("support-requests/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.SupportRequests = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getSupportStatus();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.support_requests.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="SupportRequestsSearchMode"
          @click="cancelappsearchMode()"
        ></button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-2">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("support_requests.id") }}</th>
          <th scope="col">{{ $t("support_requests.user_id") }}</th>
          <th scope="col">{{ $t("support_requests.order_id") }}</th>
          <th scope="col">{{ $t("support_requests.status_id") }}</th>
          <th scope="col">{{ $t("support_requests.created") }}</th>
          <th scope="col">{{ $t("support_requests.updated") }}</th>
          <th scope="col">{{ $t("popups.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in SupportRequests"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app?.user?.name }}</td>
          <!-- <td>{{ app.support_id }}</td> -->
          <td>{{ app.order_id }}</td>
          <td>
            {{
              $i18n.locale == "ar" ? app?.status?.name_ar : app?.status?.name
            }}
          </td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td>
            <a
              data-bs-toggle="modal"
              data-bs-target="#requestInfo"
              @click="selectedRequest = app"
              class="btn btn-info"
              href="javascript: void(0);"
              role="button"
              >{{ $t("support_requests.show_info") }}</a
            >
            <router-link
              :to="'/tech-support/' + app.id"
              class="btn btn-success mx-1"
              href="javascript: void(0);"
              role="button"
            >
              {{ $t("popups.chat") }}
            </router-link>
            <a
              class="btn btn-warning"
              @click="changeIt(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.change_status") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div>
      <!--   Apps  paginate     -->
      <ul class="pagination pagination-rounded justify-content-end mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>

  <div
    class="modal fade"
    id="requestInfo"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("support_requests.support_id") }} #{{ selectedRequest?.id }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <div class="row p-2">
              <p>
                {{ $t("support_requests.question_title") }} :
                {{
                  $i18n.locale == "ar"
                    ? selectedRequest?.support?.title_ar
                    : selectedRequest?.support?.title
                }}
              </p>
              <p>
                {{ $t("support_requests.question_sub_title") }} :
                {{
                  $i18n.locale == "ar"
                    ? selectedRequest?.support?.sub_title_ar
                    : selectedRequest?.support?.sub_title
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
